<template>
  <nav-header :title="navTitle"></nav-header>
  <div>
    <div class="container">
      <div class="alert alert-primary mt-3 mb-3" role="alert">
        <b>
          {{
            $englishPreferred
              ? "Countdown - 5 minutes"
              : "Nedtælling - 5 minutter"
          }}</b
        ><br />
        <p>Nedtællingsur til brug for notering i notesbog eller lignende. Hvis du ønsker at indtaste observationer i appen skal du vælge "Start ny tælling" i hovedmenuen.</p>
        <i class="material-icons button-material-icon">play_arrow</i>{{
          $englishPreferred
            ? 'When you press "Start countdown" the timer starts'
            : 'Når du trykker "Start nedtælling" går timeren i gang'
        }}<br />
        <i class="material-icons button-material-icon">audiotrack</i>{{
          $englishPreferred
            ? "When the 5 minutes has passed you will hear a completion sound"
            : "Når de 5 minutter er gået vil du høre en afslutningsmelodi"
        }}<br />
        <i class="material-icons button-material-icon">smartphone</i>{{
          $englishPreferred
            ? "The screen is kept awake, don't turn it off manually"
            : "Skærmen holdes vågen, sluk den ikke manuelt"
        }}<br />
      </div>
      <Transition>
        <div
          v-if="!timerStarted"
          class="row text-center justify-content-center"
        >
          <div class="col-12">
            <button
              @click="startCountdown()"
              type="button"
              class="btn btn-primary btn-lg"
            >
            <i class="material-icons button-material-icon">play_arrow</i>
              {{ $englishPreferred ? "Start countdown" : "Start nedtælling" }}
            </button>
          </div>
        </div>
      </Transition>
    </div>
    <div class="bottom-nav">
      <button
        @click="stopTimer"
        class="btn btn-outline-primary bottom-action"
        type="button"
      >
        <h1 class="counter-time">
          <i
            class="material-icons md-38"
            v-bind:class="{ 'text-danger': timeout }"
          >
            timer
          </i>
          <span>{{ timerCountWithMinutes }}</span>
        </h1>
      </button>
      <button
        v-if="timeout"
        type="button"
        class="btn bottom-action btn-primary"
        @click="$router.push('/')"
      >
        <i class="material-icons button-material-icon"> done </i>
        {{ $englishPreferred ? "Done" : "Afslut" }}
      </button>
    </div>
  </div>
</template>

<script>
const alarmSoundUrl = null;
const alarmSound = null;
import localforage from "localforage";
import NoSleep from "nosleep.js";
import NavHeader from "@/components/navbars/NavHeader.vue";
export default {
  name: "AddCompletedCount",
  components: {
    localforage,
    NavHeader,
  },
  data() {
    return {
      noSleep: null,
      navTitle: this.$englishPreferred ? "Countdown" : "Nedtællingsur",
      timeout: false,
      countdownSeconds: 300,
      startTime: null,
      secondsPassed: null,
      timeStopped: false,
      timerStarted: false,
    };
  },
  mounted() {
    localforage.getItem("audio").then((audio) => {
      if (audio) {
        this.alarmSoundUrl = URL.createObjectURL(audio);
      }
    });
  },
  methods: {
    startCountdown() {
      this.timerStarted = true;
      // Prepare audio for play
      this.alarmSound = new Audio(this.alarmSoundUrl);
      this.alarmSound.src = this.alarmSoundUrl;

      // Keep screen awake
      this.noSleep = new NoSleep();
      this.noSleep.enable();
    },
    stopTimer() {
      if (this.timeout === true) {
        this.timeStopped = true;
        this.alarmSound.pause();
      }
    },
  },
  watch: {
    secondsPassed: {
      handler(value) {
        setTimeout(() => {
          if (this.timeStopped === false) {
            this.secondsPassed = Date.now() / 1000 - this.startTime / 1000;
            if (
              this.timeout == false &&
              this.secondsPassed > this.countdownSeconds
            ) {
              this.timeout = true;
              this.noSleep.disable();
              this.alarmSound.play();
              navigator.vibrate(4000);
            }
          }
        }, 100);
      },
    },
    timerStarted() {
      this.startTime = Date.now();
      this.secondsPassed = 0;
    },
  },
  computed: {
    timerCountWithMinutes() {
      let totalSecondsLeft = this.timeout
        ? this.secondsPassed - this.countdownSeconds
        : this.countdownSeconds - this.secondsPassed;

      let minutesLeft = Math.floor(totalSecondsLeft / 60);
      let secondsLeft = Math.floor(totalSecondsLeft % 60);

      let string = "";
      if (this.timeout == true) {
        string += "-";
      }
      if (minutesLeft < 10) {
        string += "0";
      }
      string += minutesLeft;
      string += ":";
      if (secondsLeft < 10) {
        string += "0";
      }
      string += secondsLeft;
      return string;
    },
  },
};
</script>

<style scoped>
.md-38 {
  position: relative;
  /* Adjust these values accordingly */
  top: 9px;
  font-size: 60px !important;
}

h1 {
  font-size: 60px;
}

.btn-outline-primary {
  background-color: #ffff;
}

.bottom-nav {
  height: 150px;
}

.counter-time {
  color: #1f7aaf;
}
</style>
